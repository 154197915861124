@import "./app/shared/styles/variables.scss";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: $bg-color;
}
*,
input,
select,
textarea,
option,
button {
  outline: none !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
