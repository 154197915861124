@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~@angular/material/theming";
@import "./variables.scss";
@import "./color-palette.scss";

@include mat-core();
/* Recources
    Generate Palette: http://mcg.mbitson.com
    Note: Everytime one changes a color in ./variables, new palette must be generated
*/

$my-app-primary: mat-palette($primary-palette);
$my-app-accent: mat-palette($accent-palette);
$my-app-warn: mat-palette($warn-palette);

$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-accent);
@mixin mix-app-theme($my-app-theme) {
  $primary: map-get($my-app-theme, primary);
  $accent: map-get($my-app-theme, accent);

  .mat-button-toggle-checked {
    background-color: mat-color($primary);
    color: #fff;
  }
}

@include angular-material-theme($my-app-theme);
@include mix-app-theme($my-app-theme);

html,
body {
  font-family: $font-family;
  height: 100%;
  font-size: 14px;
  margin: 0;
}

@import "./theme/button.scss";
@import "./theme/table.scss";
@import "./theme/chip.scss";
@import "./utils.scss";
