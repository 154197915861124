/* Colors */
$bg-color: #ecf5fd;
$base-color: #10d53a;
$accent-color: #86CD5E;
$base-color-dark: green;
$header-color: #0d2b48;
$input-color: #3d3d3d;
$danger-color: #f23939;
$label-color: #a5a5a5;
$border-color: #ccc;
$upload-photo-bg-color: #ecf5fd;

// Menu
$menu-shadow: 0 5px 25px #00000029;
$menu-bg: #fff;
$menu-item-color: #0d2b48;
$menu-item-active-color: $base-color;

/* Fonts */
$font-family: Helvetica;
$panel-font-size: 14px;
$heading-font-size: 16px;
$heading-font-weight: bold;

/* Sizes */
$header-height: 100px;
$media-image-size-w: 200px;
$media-image-size-h: 150px;
$side-menu-width: 280px;
$default-content-padding-top: 110px;
$widget-panel-width: 360px;
$widget-panel-height: 330px;
$widget-bottom-margin: 15px;
$left-panel-width: 340px;
$top-navigation-height: 150px;
$small-top-navigation-height: 60px;
$default-widget-container-width: 700px;
$pagination-pill-size: 30px;

/* Offests */
$default-offset: 24px;
$default-medium-offset: 16px;
$default-half-offset: calc($default-offset / 2);
$default-small-offset: 8px;
$default-mobile-offset: $default-offset;

/* Mixins */
@mixin linear-gradient($direction, $color-stops...) {
  // Direction has been omitted and happens to be a color-stop
  @if is-direction($direction) == false {
    $color-stops: $direction, $color-stops;
    $direction: 180deg;
  }

  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}
@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $top $left $blur $color;
    -moz-box-shadow: inset $top $left $blur $color;
    box-shadow: inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}
