@import "./variables.scss";

// Text & Fonts
.text-center {
  text-align: center;
}
// Width

.fullwidth {
  display: inline-block;
  width: 100%;
}

// Offsets
.no-margin {
  margin: 0;
}

.mt-25 {
  margin-top: 25px;
}

.ml-25 {
  margin-left: 25px;
}

.mr-25 {
  margin-right: 25px;
}

// Shadow
.container-shadow {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.no-records-found {
  width: 100%;
  text-align: center;
  padding: 16px;
  font-style: italic;
}

.order-status-pill {
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-right: 5px;
  border-radius: 50%;

  &.order-reserved {
    background-color: #2dce98;
  }
  &.order-sold {
    background-color: #fbc140;
  }
  &.order-canceled {
    background-color: #f53c56;
  }
}
