.mat-raised-button {
  &.mat-button-base {
    width: 150px;
  }
}

.primary-button {
  width: 150px;
  padding: 5px 0;
  color: #fff;
  border: none;
  background-image: linear-gradient(90deg, $header-color 0%, $base-color 50%, #007b38 100%);
  border-radius: 18px;
  &[disabled] {
    background: #ccc;
  }
  &.cancel-button {
    background: #ccc;
  }
}
